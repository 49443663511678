@import "../../styles/colors.scss";

.switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 8px;
  text-align: left;
}

.switch .switch-check {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 36px;
  height: 18px;
  margin-right: 20px;
  border-radius: 26px;
  background-color: $gray4;
  transition: all 0.25s ease-out 0s;
}

.switch.active .switch-check {
  background-color: $indigo;
  transition: all 0.25s ease-out 0s;
}

.switch .switch-check:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $ui-bg-white;
  box-shadow: 0 0 2px #a7a9ab;
  transition: all 0.25s ease-out 0s;
}

.switch.active .switch-check:after {
  left: 18px;
  transition: all 0.25s ease-out 0s;
}

.switch .switch-label {
  flex-grow: 1;
  flex-shrink: 1;
  color: $ui-text-dark;
  font-size: 12px;
  font-weight: 500;
}
