@import "../../styles/colors.scss";


.cookie-notice {
  position: fixed;
  bottom: 50px;
  right: 0;
  left: 80%;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none
}

@media(min-width: 1280px) {
  .cookie-notice {
    bottom:125px
  }
}

.cookie-notice__item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 12px;
  background: hsla(0, 0%, 0%, 0.295); 
  border-radius: 100px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px)
}

.cookie-notice__image-wrapper {
  position: relative;
  margin-right: 16px;
  border-radius: 50%;
  overflow: hidden
}

.notification-enter-active, .notification-leave-active {
  transition-property: opacity,margin-top,transform;
  transition-duration: .5s
}

.notification-fade-enter, .notification-leave-to {
  margin-top: -104px;
  opacity: 0;
  transform: translateY(84px)
}


.cookie-notice__image-wrapper--leave:before {
  display: block;
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -2px;
  background: #fff;
  opacity: .5;
  transform: rotate(-45deg)
}

.cookie-notice__image {
  border-radius: 50%;
  height: 80px;
}

.cookie-notice__text {
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  margin-right: 24px;
  color: #fff;
  text-align: center
}

 