@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.home-page {
  flex: 1 1;
  padding: 20px 0;

  .home-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

.product-add-info-form {
  .table-adaptive {
    padding-bottom: 20px;
  }
}

#page.product-add-page {
  margin: 20px 0;
  background-color: $ui-bg-white;

  .product-add-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;

    .product-add-about {
      .product-add-about-title {
        margin-bottom: 20px;
      }
    }

    .product-add-info-photo {
      margin: 10px 0;

      .card-add-photo-wrap {
        max-width: 250px;
      }
    }
  }

  .product-add-info-tags-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 12px 0;
  }



  .product-add-info-tags {
    border-radius: 20px;
    //background-color: $purple;
    color: $ui-bg-white;
    padding: 10px 8px;
    width: 120px;
    margin: 2px;
    position: relative;


    p {
      display: inline-block;
      width: 60px;
    }

    button {
      position: absolute;
      right: 10px;
      top: 7px;
      display: inline-block;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      background-color: $ui-bg-white;
      color: $red;
    }


    /* colors line */
    &.red {

      background-color: $red;
    }

    &.pink {

      background-color: $pink;
    }

    &.purple {

      background-color: $purple;

    }

    &.violet {

      background-color: $purple;

    }

    &.primary {

      background-color: $indigo;

    }

    &.blue {

      background-color: $blue;

    }

    &.sky {

      background-color: $sky;
    }


    &.cyan {

      background-color: $cyan;

    }

    &.teal {

      background-color: $teal;

    }

    &.grass {

      background-color: $lime;

    }

    &.green {

      background-color: $green;

    }

    &.lime {

      background-color: $olive;

    }

    &.yellow {

      background-color: $yellow;

    }

    &.amber {

      background-color: $amber;

    }

    &.orange {

      background-color: $orange;

    }

    &.brick {

      background-color: $brick;

    }

    &.gray {

      background-color: $gray;

    }

    &.dark {

      background-color: $dark;

    }

  }




}

@media all and (max-width: $ipad) {
  .home-page {
    padding: 20px 0;

    .home-info {
      grid-template-columns: 1fr;
    }
  }

  #page.product-add-page {
    .product-add-wrap {
      grid-template-columns: 1fr !important;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {}