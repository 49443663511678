@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.rdw-image-modal {
  left: -200px;
}

.home-page {
  flex: 1 1;
  padding: 20px 0;

  .home-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

#page.product-add-page {
  margin: 20px 0;
  background-color: $ui-bg-white;

  .product-add-wrap {
    display: grid;
    grid-template-columns: 1fr  ;
    grid-gap: 20px;
    padding: 20px;

    .product-add-about {
      .product-add-about-title {
        margin-bottom: 20px;
      }
    }

    .product-add-info-photo {
      margin: 10px 0;

      .card-add-photo-wrap {
        max-width: 250px;
      }
    }
  }
}

@media all and (max-width: $ipad) {
  .home-page {
    padding: 20px 0;

    .home-info {
      grid-template-columns: 1fr;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
