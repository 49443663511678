@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.board-wrap {
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: scroll;
}

.board-scroll {
  width: auto;
}

.board-filters {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 16px;
  margin-bottom: 16px;
}

.board {
  flex-direction: row;
  grid-gap: 4px;

  .board-column {
    position: relative;
    grid-gap: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 320px;
    margin-top: 70px;
    padding: 6px 4px;
    border-radius: 4px;
    background-color: $ui-bg-light;
    transition: all 0.3s ease-out 0s;

    .board-title {
      position: absolute;
      top: -70px;
    }
  }

  /*.board-add-column {
    flex-direction: row;
    align-items: center;
    width: 320px;
    height: 57px;
    padding: 0 20px;
    border: 1px solid $ui-line-gray;
    border-radius: 4px;
  }*/
}

.board-close {
  position: fixed;
  left: 0;
  bottom: 20px;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(100% - 40px);
  height: 60px;
  margin: 20px;
  border: 1px solid $ui-line-gray;
  border-radius: 4px;
  transition: all 0.3s ease-out 0s;

  p {
    color: $green;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

/* desktop */
@media (pointer: fine) {
  .board {
    .board-column {
      &:hover {
        background-color: $ui-bg-gray;
        transition: all 0.3s ease-out 0s;
      }
    }
  }

  .board-close {
    &:hover {
      cursor: pointer;
      background-color: $ui-bg-gray;
      transition: all 0.3s ease-out 0s;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
