@import "../../../styles/colors.scss";
@import "../../../styles/media.scss";

.board-card {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 312px;
  padding: 8px;
  border: 1px solid $ui-line-gray;
  border-radius: 4px;
  background-color: $ui-bg-white;
  transition: all 0.3s ease-out 0s;

  .board-card-title {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
    padding: 0 12px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      color: $ui-text-gray;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .board-card-members {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 16px;

    .board-card-member {
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-right: 4px;
      border-radius: 50%;
      background-color: $indigo;

      p {
        color: $ui-text-white;
        font-size: 10px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }

  .board-card-info {
    width: 100%;
    padding: 10px 12px;
    border-radius: 8px;
    background-color: $ui-bg-light;

    p {
      opacity: 0.7;
    }
  }
}

/* desktop */
@media (pointer: fine) {
  .board-card {
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 4px rgba($ui-shadow, 0.05);
      transform: translateY(-2px);
      transition: all 0.3s ease-out 0s;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
