@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.menu-left {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: $ui-bg-white;

  .menu-left-list {
    position: relative;
    z-index: 2;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .menu-left-item {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 1;
      height: 60px;
      background-color: $ui-bg-white;

      .menu-left-item-wrap {
        position: relative;
        z-index: 2;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background-color: $ui-bg-white;
      }

      &:hover {
        span {
          left: 60px;
          transition: all 0.45s ease-out 0s;
        }
      }

      span {
        position: absolute;
        left: -200px;
        z-index: 1;
        padding: 8px;
        border-radius: 2px;
        color: $ui-text-white;
        font-size: 14px;
        line-height: 24px;
        background-color: $ui-bg-dark;
        white-space: nowrap;
      }

      .menu-left-item-icon {
        width: 24px;
        height: 24px;
        fill: $ui-icon-menu;
      }

      &.active {
        background-color: $ui-bg-light;

        .menu-left-item-wrap {
          background-color: $ui-bg-light;
        }

        span {
          color: $ui-text-brand;
          font-weight: 600;
        }

        .menu-left-item-icon {
          fill: $ui-icon-brand;
        }
      }
    }
  }

  /* left menu panel */
  .menu-left-panel {
    position: absolute;
    z-index: 1;
    left: 100%;
    width: 320px;
    height: 100%;
    padding: 12px;
    background-color: $ui-bg-light;
    box-shadow: 4px 0 12px -8px rgba(80, 89, 112, 0.35);
    transform: translateX(-320px);
    transition: all 0.35s ease-out 0.05s;
    overflow: hidden;
    overflow-y: auto;

    &.active {
      transform: translateX(0);
      transition: all 0.35s ease-out 0.05s;
    }

    .menu-left-panel-list {
      width: 100%;

      .menu-left-panel-item {
        display: flex;
        padding: 12px 20px;
        border-radius: 8px;

        span {
          color: $ui-text-dark;
          font-size: 14px;
          line-height: 24px;
          white-space: nowrap;
        }

        &.active {
          background-color: $gray2;

          span {
            color: $ui-text-white;
          }
        }
      }
    }
  }
}

@media all and (max-width: $ipad) {
  .menu-left {
    position: fixed;
    top: 64px;
    left: 0;
    z-index: 999;
    width: 100%;
    min-height: calc(100vh - 64px);
    background-color: $ui-bg-white;
    transform: translateX(100%);
    transition: all 0.35s ease-out 0s;

    &.active {
      transform: translateX(0);
      transition: all 0.35s ease-out 0s;
    }

    .menu-left-list {
      align-items: flex-start;

      .menu-left-item-case {
        width: 100%;
      }

      .menu-left-item {
        display: flex;
        width: 100%;
        justify-content: flex-start;

        span {
          position: static;
          background-color: transparent;
          color: $ui-text-dark;
        }
      }
    }

    .menu-left-panel {
      position: fixed;
      top: 0;
      left: 64px;
      width: calc(100% - 64px);
      z-index: 9999;
      transform: translateX(100%);

      &.active {
        transform: translateX(0);
      }
    }
  }
}

@media all and (max-width: $mobile) {
  /*.menu-left {
    height: 84px;

    .menu-left-list {
      flex-wrap: wrap;
      padding: 0;
      padding: 0;

      .menu-left-item {
        flex: auto;
        height: 50%;
        margin-bottom: 0;

        span {
          font-size: 10px;

          span {
            display: none;
          }
        }

        .menu-left-item-icon {
          width: 28px;
          height: 28px;
          margin-bottom: 6px;
        }
      }
    }
  }*/
}
