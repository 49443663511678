@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.header {
  position: relative;
  z-index: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 64px;
  padding: 0 1rem;
  background-color: $ui-bg-dark;

  .header-menu-mobile {
    display: none;
  }

  .header-cards-wrap {
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: 1;
    flex-shrink: 1;
    border: 1px solid $ui-line-header;
    border-radius: 4px;

    .header-card {
      p {
        color: $ui-text-light;
        font-size: 14px;
        font-weight: 800;

        span {
          color: $sky;
        }
      }
    }
  }

  .header-profile-wrap {
    flex-direction: row;
    height: 100%;
  }

  .header-dropdown {
    position: absolute;
    top: calc(100% - 20px);
    right: 0;
    width: 264px;
    background-color: $ui-bg-white;
    box-shadow: 0 0 19px rgba(90, 90, 90, 0.32);

    .header-dropdown-outside {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    .header-dropdown-inner {
      position: relative;
      z-index: 1;
      width: 100%;
    }

    h3 {
      padding: 8px 12px;
      border-bottom: 1px solid $ui-line-gray;
      color: $ui-text-dark;
      font-size: 12px;
      font-weight: 700;
    }

    .header-dropdown-profile {
      width: 100%;
      padding: 15px 24px 0 24px;

      p {
        margin-bottom: 10px;
        color: $ui-text-dark;
        font-size: 16px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .header-profile-buttons-wrap {
      margin: 0 24px;
      padding-bottom: 16px;
      border-bottom: 1px solid $ui-line-gray;

      .header-profile-inner-button {
        justify-content: flex-start;
        padding: 8px 0;
        color: $ui-text-dark;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .header-profile-exit {
      padding: 17px 24px;
      color: $ui-text-dark;
      font-size: 16px;
      font-weight: 600;
    }

    .header-dropdown-inner {
      .header-dropdown-list {
        padding: 9px 12px;

        .header-dropdown-item {
          padding: 10px 0;
          border-bottom: 1px solid #f2ebef;

          p {
            color: $ui-text-dark;
            font-size: 12px;
            font-weight: 600;

            span {
              color: $blue;
            }
          }

          &:last-child {
            border-bottom-width: 0;
          }
        }
      }

      .header-dropdown-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 6px;
        padding-bottom: 10px;
        background-color: #f3f7fa;

        span {
          color: $blue;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

@media all and (max-width: $macbook) and (min-width: $desktop) {
  .header {
    grid-template-columns: 2fr 9fr 1fr;
    grid-gap: 1rem;
  }
}

@media all and (max-width: $ipad) and (pointer: fine) {
  .header {
    grid-gap: 1rem;
    justify-content: flex-start;

    .header-menu-mobile {
      display: flex;

      svg {
        fill: #fff;
      }
    }

    .header-cards-wrap {
      display: none;
    }
  }
}

@media all and (max-width: $ipad) and (pointer: coarse) {
  .header {
    grid-template-columns: 1fr;
    justify-content: flex-start;
    height: auto;
    padding: 36px 40px;

    .header-menu-mobile {
      display: flex;
      padding: 20px;

      svg {
        fill: #fff;
      }
    }

    & > h2 {
      text-align: center;
    }

    .header-cards-wrap {
      display: none;
    }

    .header-profile-wrap {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      width: auto;
      height: 92px;
      padding: 0 20px;
    }
  }
}

@media all and (max-width: $mobile) {
  .header {
    grid-template-columns: 1fr;
    grid-gap: 0;
    height: 64px;
    padding: 8px 0;

    .header-dropdown {
      top: 100%;
    }

    & > h2 {
      text-align: center;
    }

    .header-profile-wrap {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      //width: 100%;
      height: 100%;
      padding: 0 4px;
    }
  }
}

@media all and (max-width: $small) {
  .header {
    .header-profile-wrap {
      padding: 0;
    }
  }
}
